<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Adres</h3>
        <router-link
        v-if="addButtonActive"
          to="/profile/address/create"
          type="button"
          style="position: absolute; right: 25px"
          class="btn btn-primary"
        >
          Ekle
        </router-link>
        <div style="position: absolute; right: 90px">
          <button @click="openHelperVideo" class="btn" title="Bilgi edin"><i class="fa fa-info-circle"  style="font-size:25px;color: rgb(187, 116, 148);" ></i></button>
          <div
            class="modal fade"
            id="videoHelper"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <video controls>
                  <source
                    src="https://storage.terapivitrini.com/storage/profile/adres.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div
            v-for="(item, i) in addressList"
            :key="i"
            class="col-sm-12 col-md-12"
          >
            <div class="card col-sm-12" style="padding: 10px">
              {{ item.address_title }}
              <router-link
                title="Düzenle"
                :to="'/profile/address/update/' + item.id"
                style="position: absolute; right: 50px"
                ><i class="side-menu__icon si si-pencil"></i
              ></router-link>
              <a
                title="Sil"
                @click="removeItem(item.id)"
                style="position: absolute; right: 20px; cursor: pointer"
                ><i class="side-menu__icon si si-trash"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  created() {
    document.title = "Adreslerim";
    this.$store.dispatch("addressListGet").then((value) => {
      this.addressList = value;
      if(this.addressList.length==0){
        this.addButtonActive=true;
      }
    });
  },
  data() {
    return {
      addressList: [],
      addButtonActive:false,
    };
  },
  methods: {
    openHelperVideo() {
      $("#videoHelper").modal("show");
    },
    removeItem(id) {
      Swal.fire({
        title: "Bu adresi silmek istediğine emin misin?",
        text: "Bu işlem sonrası seçilen adres silinecek, devam etmek istiyor musun?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Devam",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          let phoneSave = {
            id: id,
          };
          this.$store.dispatch("addressRemove", phoneSave).then((value) => {
            if (value.type == "error") {
              if (value.message == "ERRxUAR") {
                this.$vToastify.warning(
                  "Onay sürecindeki profil güncellenemez",
                  "Uyarı!"
                );
              }
            } else {
              this.$vToastify.success(
                "Adres bilgileri başarılı bir şekilde silindi",
                "Başarılı!"
              );
              this.$store.dispatch("addressListGet").then((value) => {
                this.addressList = value;
              });
            }
          });
        }
      });
    },
  },
  components: {},
  mounted() {},
};
</script>